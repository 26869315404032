import React from "react";
import { Button, Form, Input, Row, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import { createTheme, ThemeProvider } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { signInAction } from "../store/actions/authAction";
import { useDispatch } from "react-redux";
import { InputGroup, InputGroupText } from "reactstrap";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const LoginForm = () => {
    const { darkMode, color } = useSelector((state) => state.global);
    const [showPassword, setShowPassword] = useState(false);
    const history = useHistory();
    const [signInData, setSignInData] = useState({
        email: "",
        password: "",
    });
    const handleChangeData = (e) => {
        const { name, value } = e.target;
        setSignInData((prevData) => ({
            ...prevData,
            [name]: value,
        }));
    };

    const theme = createTheme({
        palette: {
            secondary: {
                main: `${color ? color : "#000000"}`,
            },
        },
    });

    const dispatch = useDispatch();
    const { loading } = useSelector((state) => state.user);

    let cartPath = localStorage.getItem("cartPath");
    const handleSubmit = () => {
        dispatch(
            signInAction(signInData, () => {
                cartPath ? history.push("/cart") : history.push(`/restaurants`);
            })
        );
    };
    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            e.preventDefault();
            handleSubmit(); // Call form submit function
        }
    };

    return (
        <Form
            className={darkMode ? "px-3 pay-with-card" : "px-3 "}
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
            }}
            onKeyDown={handleKeyDown}
        >
            <ThemeProvider theme={theme}>
                <div className="py-2">
                    <label>Email</label>
                    <Input
                        required
                        placeholder="Enter email"
                        autoComplete="on"
                        className="py-4"
                        type="email"
                        name="email"
                        value={signInData.email}
                        onChange={handleChangeData}
                        style={{
                            borderColor: darkMode
                                ? "#ffffff"
                                : "rgba(0, 0, 0, 0.125)",
                        }}
                    />
                </div>
                <div className="py-2">
                    <label>Password</label>
                    <InputGroup>
                        <Input
                            type={showPassword ? "text" : "password"}
                            name="password"
                            required
                            className="py-4"
                            autoComplete="on"
                            placeholder="Enter Password"
                            value={signInData.password}
                            onChange={handleChangeData}
                            style={{
                                borderRight: "none",
                                borderColor: darkMode
                                    ? "#ffffff"
                                    : "rgba(0, 0, 0, 0.125)",
                            }}
                        />
                        <InputGroupText
                            style={{
                                cursor: "pointer",
                                background: "#fff",
                                borderLeft: "none",
                                borderTopLeftRadius: "0px",
                                borderBottomLeftRadius: "0px",
                            }}
                            onClick={() => setShowPassword(!showPassword)}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}{" "}
                        </InputGroupText>
                    </InputGroup>
                </div>
            </ThemeProvider>
            {}
            <Button
                className="py-3 mt-4 w-100 auth__btn"
                style={{ background: `${color}` }}
                type="submit"
                disabled={loading}
            >
                {loading ? <Spinner size="md" /> : `${"Login"}`}
            </Button>
            {}

            <p className="mt-3 text-center">
                Don’t have an account?{" "}
                <span
                    className="cursor-pointer"
                    style={{ color: `${color}` }}
                    onClick={() => history.push("/register")}
                >
                    Register Now
                </span>
            </p>
        </Form>
    );
};

export default LoginForm;
