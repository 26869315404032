import React, { useEffect, useState, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { GoogleMap, Marker } from "@react-google-maps/api";
import {
    Button,
    Col,
    Container,
    FormGroup,
    Input,
    InputGroup,
    Label,
    Row,
} from "reactstrap";
import MainNavigation from "../components/MainNavigation";
import OrderedItems from "../components/OrderedItems";
import PricingSection from "../components/PricingSection";
import Shipment from "../components/Shipment";
import {
    getCheckoutQuestions,
    updateDelivery,
    updateTable,
    updateTip,
} from "../store/actions/cartAction";
import { getRestaurantsAction } from "../store/actions/restaurantsAction";

const Cart = () => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [Address1, setAddress1] = useState({ lat: "", lng: "", address: "" });
    console.log(Address1, "Address1>>>>>>>>>>");
    const [predictions, setPredictions] = useState([]);
    console.log(predictions, "predictions>>>>>>");
    const [addressInput, setAddressInput] = useState("");
    const containerRef = useRef(null);
    const [autocompleteService, setAutocompleteService] = useState(null);
    const { darkMode, color } = useSelector((state) => state.global);
    let { uid, user } = useSelector((state) => state.authUser);
    const {
        cartItems,
        checkoutQuestions,
        tipDetails,
        tableDetails,
        addressDetails,
    } = useSelector((state) => state.cart);
    const { restaurantsDetail } = useSelector((state) => state.restaurants);
    let restaurantID = localStorage.getItem("restaurantID");
    const tipButtons = restaurantsDetail?.tipPercentages;
    const [activeTip, setActiveTip] = useState(-1);
    const [tipAmountValue, setTipAmountValue] = useState("");
    const [answers, setAnswers] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [Price, setPrice] = useState(0);
    const [tip, setTip] = useState(0);
    const [deliverTable, setDeliverTable] = useState("");
    console.log(deliverTable, "deliverTable>>>>>>>>>");
    const [tableNumber, setTableNumber] = useState(0);
    const [cartData, setCartData] = useState();

    let currency = cartItems?.[0]?.currency;

    const totalPriceItem = cartData?.reduce((acc, item) => {
        return acc + item.totalPrice;
    }, 0);

    useEffect(() => {
        let temp = [];
        cartItems?.forEach((obj) =>
            temp.push({
                ...obj,
                images: obj.images,
                totalPrice: obj.totalPrice,
            })
        );
        setCartData(temp);
    }, [cartItems]);
    useEffect(() => {
        const totalPrice = cartData?.reduce((acc, item) => {
            return acc + item.totalPrice;
        }, 0);
        setTip(0);
        setActiveTip(-1);
        setPrice(totalPrice);
        setTotalAmount(totalPrice);
    }, [cartData]);

    useEffect(() => {
        if (tipDetails) {
            if (tipDetails?.tipType == "fixed") {
                setTip(tipDetails?.tip);
                setTipAmountValue(tipDetails?.tip);
                setTotalAmount(Price + tipDetails?.tip);
                setActiveTip(-1);
            } else if (tipDetails?.tipType == "percentage") {
                let price = (tipDetails?.tip / 100) * Price;
                console.log(price, "price");
                setTip(price);
                setTotalAmount(Price + price);
                setActiveTip(tipDetails?.tipIndex);
                setTipAmountValue(0);
            }
        }
    }, [tipDetails]);

    useEffect(() => {
        if (tableDetails) {
            setTableNumber(tableDetails);
            setDeliverTable("table");
        }
    }, [tableDetails]);

    useEffect(() => {
        if (restaurantID) {
            dispatch(getRestaurantsAction(restaurantID));
        }
    }, [restaurantID]);

    const shipment = {
        storeName: restaurantsDetail?.name
            ? restaurantsDetail?.name
            : "Sample Restaurant",
        shipmentFrom: "From Store",
    };

    useEffect(() => {
        window.scrollTo(0, 0);
        dispatch(getCheckoutQuestions(restaurantID));
    }, [restaurantID]);

    const handleChange = (event, questionId) => {
        setAnswers((prevState) => ({
            ...prevState,
            [questionId]: {
                answer: event.target.value,
                customerId: uid,
                questionId,
                customerName: user?.name,
                customerAge: user?.age,
                customerGender: user?.gender,
            },
        }));
    };
    useEffect(() => {
        if (window.google) {
            setAutocompleteService(
                new window.google.maps.places.AutocompleteService()
            );
        }
    }, []);
    const onPlaceChanged = useCallback((place) => {
        if (place && place.geometry) {
            const { location } = place.geometry;
            setAddressInput(place.formatted_address);
            setAddress1({
                lat: location.lat(),
                lng: location.lng(),
                address: place.formatted_address,
            });
            if (
                location?.lat() &&
                location?.lng() &&
                place?.formatted_address
            ) {
                dispatch(
                    updateDelivery({
                        lat: location?.lat(),
                        lng: location?.lng(),
                        address: place?.formatted_address,
                    })
                );
            }
        }
    }, []);
    const handleInputChange = (e) => {
        const input = e.target.value; // Update addressInput state
        console.log(input, "country name>>>>>>");
        setAddressInput(input);
        if (autocompleteService) {
            autocompleteService.getPlacePredictions(
                {
                    input,
                    // componentRestrictions: { country: "gb" },
                    // bounds: new window.google.maps.LatLngBounds(
                    //     { lat: 55.278052, lng: -3.535973 },
                    //     { lat: 55.478052, lng: -3.335973 }
                    // ),
                    // strictBounds: true,
                },
                (results) => {
                    setPredictions(results || []);
                }
            );
        }
    };

    const handlePlaceSelect = (prediction) => {
        setAddressInput(prediction?.description);
        if (prediction && prediction.place_id) {
            const geocoder = new window.google.maps.Geocoder();
            geocoder.geocode(
                { placeId: prediction.place_id },
                (results, status) => {
                    if (status === "OK" && results[0]) {
                        onPlaceChanged(results[0]);
                    }
                }
            );
            setPredictions([]);
        }
    };

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                containerRef.current &&
                !containerRef.current.contains(event.target)
            ) {
                setPredictions([]);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);
    const defaultCenter = { lat: 51.5074, lng: -0.1278 };
    const today = new Date().toLocaleDateString("en-US", { weekday: "long" });
    const isDeliveryAvailableToday =
        restaurantsDetail?.deliveryDays?.length > 0 &&
        restaurantsDetail?.deliveryDays.some((deliveryDay) => {
            if (deliveryDay.day !== today) return false;
            const now = new Date();
            const currentTime = `${String(now.getHours()).padStart(
                2,
                "0"
            )}:${String(now.getMinutes()).padStart(2, "0")}`;
            return (
                currentTime >= deliveryDay.startTime &&
                currentTime <= deliveryDay.endTime
            );
        });
    return (
        <div className="cart">
            <Container>
                <MainNavigation title={"Cart"} />
                <Shipment data={shipment} color={color} />
                <OrderedItems data={cartData} title={"Order Details"} />
                <Row className="d-flex flex-column  mt-3">
                    <Col>
                        <h4
                            className={
                                darkMode
                                    ? " fs--18 fw--600 mb-0  normal-heading-dark"
                                    : " fs--18 fw--600 mb-0  normal-heading"
                            }
                        >
                            Add Tip
                        </h4>
                    </Col>
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                        <div className="d-flex justify-content-center align-items-center tip">
                            <Row className="tip_row my-3">
                                {tipButtons?.length > 0 &&
                                    tipButtons?.map((tip, index) => {
                                        return (
                                            <Button
                                                disabled={isNaN(tipAmountValue)}
                                                style={{
                                                    background: `${
                                                        activeTip === index
                                                            ? color
                                                            : ""
                                                    }`,
                                                }}
                                                key={index}
                                                className={
                                                    index === activeTip
                                                        ? "tip_button-active"
                                                        : "tip_button"
                                                }
                                                onClick={() => {
                                                    dispatch(
                                                        updateTip({
                                                            tip: Number(
                                                                tip.split(
                                                                    "%"
                                                                )[0]
                                                            ),
                                                            tipType:
                                                                "percentage",
                                                            tipIndex: index,
                                                        })
                                                    );
                                                }}
                                            >
                                                {tip}
                                            </Button>
                                        );
                                    })}
                            </Row>
                        </div>
                        <FormGroup
                            className={
                                darkMode ? "tip__amount-dark" : "tip__amount"
                            }
                        >
                            <Input
                                type="number"
                                placeholder="Custom Tip"
                                value={tipAmountValue}
                                min={0}
                                onChange={(e) =>
                                    setTipAmountValue(Number(e.target.value))
                                }
                                onBlur={() =>
                                    dispatch(
                                        updateTip({
                                            tip: tipAmountValue,
                                            tipType: "fixed",
                                        })
                                    )
                                }
                            />
                        </FormGroup>
                    </Col>
                </Row>
                <PricingSection
                    data={{
                        price: totalPriceItem,
                        currency: currency,
                        total: totalAmount,
                        tip: tip,
                    }}
                />
                <Row className="my-3">
                    <Col className="d-flex flex-column justify-content-center align-items-center">
                        {checkoutQuestions
                            ?.filter(
                                (question) => question.type === "marketing"
                            )
                            .map((question) => {
                                return (
                                    <FormGroup key={question?.id}>
                                        <Label
                                            className={
                                                darkMode
                                                    ? "normal-heading-dark"
                                                    : "normal-heading"
                                            }
                                        >
                                            <span
                                                className={`${
                                                    question?.isMandatory ===
                                                    true
                                                        ? "text-danger"
                                                        : "text-dark"
                                                }`}
                                            >
                                                *
                                            </span>
                                            {question?.title}?
                                        </Label>
                                        <Input
                                            type="textarea"
                                            value={
                                                answers[question?.id]?.answer ||
                                                ""
                                            }
                                            required={
                                                question?.isMandatory === true
                                            }
                                            onChange={(event) =>
                                                handleChange(
                                                    event,
                                                    question?.id
                                                )
                                            }
                                        />
                                    </FormGroup>
                                );
                            })}
                    </Col>
                </Row>
                <Row className="my-3">
                    {isDeliveryAvailableToday ? (
                        <Col className="d-flex flex-column justify-content-center align-items-center">
                            <FormGroup>
                                <Input
                                    type="radio"
                                    name="deliver"
                                    value="table"
                                    checked={deliverTable === "table"}
                                    onChange={(e) => {
                                        setDeliverTable(e.target.value);
                                    }}
                                    style={{ accentColor: `${color}` }}
                                />{" "}
                                <Label
                                    check
                                    className={`fs--16 fw--500 ${
                                        darkMode && "normal-heading-dark"
                                    }`}
                                >
                                    Do you want to deliver it on table?
                                </Label>
                            </FormGroup>
                            <FormGroup hidden={deliverTable !== "table"}>
                                <InputGroup
                                    className={
                                        darkMode
                                            ? "table__search-box-dark my-2"
                                            : "table__search-box my-2"
                                    }
                                >
                                    <div>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_811_22218"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                            >
                                                <g clipPath="url(#clip0_811_22218)">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M19.3808 3.23749L21.8808 7.40416C21.9588 7.53376 22 7.68211 22 7.83333C22.0018 8.4775 21.8165 9.10832 21.4666 9.64921C21.1167 10.1901 20.6174 10.6178 20.0291 10.8803C19.4409 11.1428 18.7891 11.2289 18.1528 11.1281C17.5166 11.0273 16.9233 10.744 16.445 10.3125C15.8357 10.8641 15.0432 11.1696 14.2213 11.1696C13.3994 11.1696 12.6068 10.8641 11.9975 10.3125C11.3885 10.8629 10.5968 11.1677 9.77584 11.1677C8.95491 11.1677 8.16321 10.8629 7.55417 10.3125C6.94336 10.8643 6.14896 11.1688 5.32584 11.1667H5.31917C4.4371 11.1645 3.59199 10.8121 2.9696 10.1871C2.3472 9.562 1.99846 8.7154 2.00001 7.83333C2.00005 7.68211 2.04125 7.53376 2.11917 7.40416L4.61917 3.23749C4.69323 3.11424 4.79791 3.01224 4.92306 2.94141C5.0482 2.87059 5.18954 2.83336 5.33334 2.83333H18.6667C18.8105 2.83336 18.9518 2.87059 19.077 2.94141C19.2021 3.01224 19.3068 3.11424 19.3808 3.23749ZM16.4475 12.31C17.1412 12.6548 17.9054 12.8339 18.68 12.8333C18.955 12.8297 19.2293 12.8035 19.5 12.755V21.1667C19.5 21.3877 19.4122 21.5996 19.2559 21.7559C19.0996 21.9122 18.8877 22 18.6667 22H13.6667V17H10.3333V22H5.33333C5.11232 22 4.90036 21.9122 4.74408 21.7559C4.5878 21.5996 4.5 21.3877 4.5 21.1667V12.7583C4.7728 12.8056 5.04898 12.8307 5.32583 12.8333C6.09892 12.8346 6.86174 12.6563 7.55417 12.3125C7.75887 12.4139 7.97018 12.5013 8.18667 12.5742C8.80604 12.7847 9.46101 12.8704 10.1137 12.8262C10.7664 12.782 11.4038 12.6088 11.9892 12.3167L11.9983 12.3125C12.2033 12.4139 12.4149 12.5013 12.6317 12.5742C13.2513 12.7843 13.9064 12.8696 14.5592 12.8252C15.2119 12.7809 15.8495 12.6078 16.435 12.3158L16.4475 12.31Z"
                                                        fill="#1A94FF"
                                                    />
                                                </g>
                                            </mask>
                                            <g mask="url(#mask0_811_22218)">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill={color}
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_811_22218">
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="white"
                                                        transform="translate(2 2)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <Input
                                        type="number"
                                        className="fs--16 fw--600"
                                        placeholder="Enter Table Number"
                                        value={tableNumber}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (
                                                /^[1-9]\d*$/.test(inputValue) ||
                                                inputValue === ""
                                            ) {
                                                setTableNumber(inputValue);
                                            }
                                        }}
                                        onBlur={() =>
                                            dispatch(updateTable(tableNumber))
                                        }
                                        onKeyPress={(e) => {
                                            if (
                                                e.key === "-" ||
                                                e.key === "e"
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <Input
                                    type="radio"
                                    name="deliver"
                                    value="address"
                                    checked={deliverTable === "address"}
                                    onChange={(e) => {
                                        setDeliverTable(e.target.value);
                                    }}
                                    style={{ accentColor: `${color}` }}
                                />{" "}
                                <Label
                                    check
                                    className={`fs--16 fw--500 ${
                                        darkMode && "normal-heading-dark"
                                    }`}
                                >
                                    Do you want to deliver it on you Address?
                                </Label>
                            </FormGroup>

                            <div
                                hidden={deliverTable !== "address"}
                                ref={containerRef}
                                className="mb-6 position-relative w-100 py-4"
                            >
                                <input
                                    type="text"
                                    required
                                    value={addressInput}
                                    onChange={handleInputChange}
                                    autoComplete="street-address"
                                    placeholder="Street address"
                                    className="w-100 px-3 py-2 rounded border"
                                    style={{ borderColor: "#e2e2e2" }}
                                />
                                {predictions.length > 0 && (
                                    <div
                                        className="position-absolute bg-white rounded border w-100"
                                        style={{
                                            borderColor: "#e2e2e2",
                                            zIndex: 10,
                                        }}
                                    >
                                        {predictions.map((prediction) => (
                                            <div
                                                key={prediction.place_id}
                                                onClick={() =>
                                                    handlePlaceSelect(
                                                        prediction
                                                    )
                                                }
                                                className="cursor-pointer p-2"
                                                style={{
                                                    backgroundColor: "#f8f9fa",
                                                }}
                                            >
                                                {prediction.description}
                                            </div>
                                        ))}
                                    </div>
                                )}
                            </div>
                            {deliverTable === "address" && (
                                <GoogleMap
                                    zoom={14}
                                    center={
                                        Address1.lat && Address1.lng
                                            ? {
                                                  lat: Address1.lat,
                                                  lng: Address1.lng,
                                              }
                                            : defaultCenter
                                    }
                                    mapContainerStyle={{
                                        height: "400px",
                                        width: "100%",
                                    }}
                                >
                                    {/* Marker for selected location */}

                                    <Marker
                                        position={{
                                            lat: Address1?.lat
                                                ? Address1?.lat
                                                : defaultCenter.lat,
                                            lng: Address1?.lng
                                                ? Address1?.lng
                                                : defaultCenter.lng,
                                        }}
                                    />
                                </GoogleMap>
                            )}
                        </Col>
                    ) : (
                        <Col className="d-flex flex-column justify-content-center align-items-center">
                            <FormGroup check>
                                <Input
                                    type="checkbox"
                                    checked={deliverTable === "table"}
                                    value="table"
                                    onChange={(e) => {
                                        if (deliverTable === "table") {
                                            setDeliverTable(""); // Uncheck by setting it to an empty value
                                        } else {
                                            setDeliverTable(e.target.value); // Check by setting the "table" value
                                        }
                                    }}
                                    style={{ accentColor: `${color}` }}
                                />{" "}
                                <Label
                                    check
                                    className={`fs--16 fw--500 ${
                                        darkMode && "normal-heading-dark"
                                    }`}
                                >
                                    Do you want to deliver it on table?
                                </Label>
                            </FormGroup>
                            <FormGroup hidden={deliverTable !== "table"}>
                                <InputGroup
                                    className={
                                        darkMode
                                            ? "table__search-box-dark my-2"
                                            : "table__search-box my-2"
                                    }
                                >
                                    <div>
                                        <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <mask
                                                id="mask0_811_22218"
                                                maskUnits="userSpaceOnUse"
                                                x="0"
                                                y="0"
                                                width="24"
                                                height="24"
                                            >
                                                <g clipPath="url(#clip0_811_22218)">
                                                    <path
                                                        fillRule="evenodd"
                                                        clipRule="evenodd"
                                                        d="M19.3808 3.23749L21.8808 7.40416C21.9588 7.53376 22 7.68211 22 7.83333C22.0018 8.4775 21.8165 9.10832 21.4666 9.64921C21.1167 10.1901 20.6174 10.6178 20.0291 10.8803C19.4409 11.1428 18.7891 11.2289 18.1528 11.1281C17.5166 11.0273 16.9233 10.744 16.445 10.3125C15.8357 10.8641 15.0432 11.1696 14.2213 11.1696C13.3994 11.1696 12.6068 10.8641 11.9975 10.3125C11.3885 10.8629 10.5968 11.1677 9.77584 11.1677C8.95491 11.1677 8.16321 10.8629 7.55417 10.3125C6.94336 10.8643 6.14896 11.1688 5.32584 11.1667H5.31917C4.4371 11.1645 3.59199 10.8121 2.9696 10.1871C2.3472 9.562 1.99846 8.7154 2.00001 7.83333C2.00005 7.68211 2.04125 7.53376 2.11917 7.40416L4.61917 3.23749C4.69323 3.11424 4.79791 3.01224 4.92306 2.94141C5.0482 2.87059 5.18954 2.83336 5.33334 2.83333H18.6667C18.8105 2.83336 18.9518 2.87059 19.077 2.94141C19.2021 3.01224 19.3068 3.11424 19.3808 3.23749ZM16.4475 12.31C17.1412 12.6548 17.9054 12.8339 18.68 12.8333C18.955 12.8297 19.2293 12.8035 19.5 12.755V21.1667C19.5 21.3877 19.4122 21.5996 19.2559 21.7559C19.0996 21.9122 18.8877 22 18.6667 22H13.6667V17H10.3333V22H5.33333C5.11232 22 4.90036 21.9122 4.74408 21.7559C4.5878 21.5996 4.5 21.3877 4.5 21.1667V12.7583C4.7728 12.8056 5.04898 12.8307 5.32583 12.8333C6.09892 12.8346 6.86174 12.6563 7.55417 12.3125C7.75887 12.4139 7.97018 12.5013 8.18667 12.5742C8.80604 12.7847 9.46101 12.8704 10.1137 12.8262C10.7664 12.782 11.4038 12.6088 11.9892 12.3167L11.9983 12.3125C12.2033 12.4139 12.4149 12.5013 12.6317 12.5742C13.2513 12.7843 13.9064 12.8696 14.5592 12.8252C15.2119 12.7809 15.8495 12.6078 16.435 12.3158L16.4475 12.31Z"
                                                        fill="#1A94FF"
                                                    />
                                                </g>
                                            </mask>
                                            <g mask="url(#mask0_811_22218)">
                                                <rect
                                                    width="24"
                                                    height="24"
                                                    fill={color}
                                                />
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_811_22218">
                                                    <rect
                                                        width="20"
                                                        height="20"
                                                        fill="white"
                                                        transform="translate(2 2)"
                                                    />
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <Input
                                        type="number"
                                        className="fs--16 fw--600"
                                        placeholder="Enter Table Number"
                                        value={tableNumber}
                                        onChange={(e) => {
                                            const inputValue = e.target.value;
                                            if (
                                                /^[1-9]\d*$/.test(inputValue) ||
                                                inputValue === ""
                                            ) {
                                                setTableNumber(inputValue);
                                            }
                                        }}
                                        onBlur={() =>
                                            dispatch(updateTable(tableNumber))
                                        }
                                        onKeyPress={(e) => {
                                            if (
                                                e.key === "-" ||
                                                e.key === "e"
                                            ) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    )}
                </Row>

                <Row className="d-flex justify-content-center p-3">
                    <Button
                        style={{ background: `${color}` }}
                        className="top_cards__addtocart fs--16 fw--400 my-4"
                        onClick={(e) => {
                            e.preventDefault();
                            if (uid) {
                                const hasEmptyMandatoryField =
                                    checkoutQuestions?.some(
                                        (question) =>
                                            question.isMandatory &&
                                            !answers[question.id]?.answer
                                    );

                                if (
                                    cartItems.length > 0 &&
                                    !hasEmptyMandatoryField
                                ) {
                                    cartItems?.forEach((item) => {
                                        delete item.images;
                                    });
                                    let restaurantID =
                                        localStorage.getItem("restaurantID");
                                    history.push({
                                        pathname: "/payment",
                                        state: {
                                            tip: tipDetails?.tip || 0,
                                            tipType: tipDetails?.tipType || "",
                                            tableNumber: tableNumber || "",
                                            deliveryAddress: addressDetails,
                                            orderType:
                                                tableDetails !== null
                                                    ? "dinein"
                                                    : addressDetails?.lat
                                                    ? "delivery"
                                                    : "takeaway",
                                            restaurantID,
                                            order: cartItems,
                                            totalAmount,
                                            totalPriceItem,
                                            answers: answers,
                                            currency: currency,
                                        },
                                    });
                                } else if (cartItems.length === 0) {
                                    toast.warning("First Add Item in Cart");
                                } else {
                                    toast.warning(
                                        "Please fill all mandatory fields."
                                    );
                                }
                            } else {
                                toast.warning("Login first to place the order");
                            }
                        }}
                        disabled={totalAmount === 0}
                    >
                        Pay - {currency} {totalAmount?.toFixed(2)}
                    </Button>
                </Row>
            </Container>
        </div>
    );
};

export default Cart;
